<template>
<div>
    <div v-if="isshowgrid">
        <va-card title="Token List">
            <div class="row">
                <div class="flex md6 mt-2">
                    <va-input class="va-input-search-container" :value="term" placeholder="search" @input="search" removable>
                        <va-icon name="fa fa-search" slot="prepend" />
                    </va-input>
                </div>
                <div class="flex md6 mt-2 text-right">
                    <va-button icon="fa fa-plus" class="my-0" @click="add()">
                        Add
                    </va-button>
                </div>
            </div>
            <va-data-table :fields="fields" :data="filteredData" :per-page="parseInt(perPage)" :totalPages="totalPages" @page-selected="getAllToken" api-mode style="min-height: 60vh;" clickable>
            </va-data-table>
        </va-card>
    </div>
    <div v-else-if='isshowForm'>
        <va-card class="container" title="Create Token">
            <template slot="actions">
                <va-button color="gray" v-if="!generated_token" @click="list()">List</va-button>
            </template>
            <div>
                <label class="va-form-label va-form-required-label">Token Name (Maximum length 50)</label>
                <va-input v-model='token_name' placeholder="Enter Token Name" required="true" :disabled="generated_token !== ''" :error="!!tokenNameError.length" :error-messages="tokenNameError" maxlength="50" />
                <label class="va-form-label va-form-required-label">Platform Type</label>
                <div class="row" style="margin-bottom: 15px;">
                    <va-radio-button class="ml-3" v-for="(option, index) in platform_radio_options" :key="index" v-model="platform_name" :option="option" :disabled="generated_token !== ''" />
                </div>
                <label class="va-form-label va-form-required-label">MSO Provider</label>
                <va-select v-model="providers" :options="providerArr" :disabled="generated_token !== ''" placeholder='Select Provider' v-if="platform_name !== 'EDGE'" textBy="brand" :error-messages="providerNameError" :error="!!providerNameError.length"></va-select>
                <label class="va-form-label va-form-required-label">Select Time</label>
                <div class="row">
                    <va-radio-button class="ml-3" v-for="(option, index) in time_radio_options" :disabled="generated_token !== ''" :key="index" v-model="time_type" :option="option" @input="getTimeType(time_type)" />
                </div>
                <va-input maxlength=5 v-model='token_time' :placeholder="change_msg" oninput="event.target.value = event.target.value.replace(/^0+/, '').replace(/^0{2,}/, '0').replace(/[^0-9]/g, '');" :error="!!selectTimeErorr.length" :error-messages="selectTimeErorr" :disabled="generated_token !== ''" />
            <br>
            </div>
            <div style="margin-bottom: 130px;">
                <div style="display: flex; align-items: center;">
                    <img src="./image.png" alt="clock icon" style="margin-right: 25px; height: 25px; width: 25px;">
                    <div>
                        <span style="color: gray;">Token will hide out in</span>
                        <br>
                        <span style="font-size: 20px;"><b>{{hide_time}}</b> Sec</span>
                    </div>
                </div>
                <div style="background-color: #eaeaea; border-radius: 10px; padding-bottom: 20px; font-size: 12px;">
                    <img src="./note.png" style="height: 20px; margin-left: 50px;">
                    <div style="display: flex; align-items: center; margin-left: 20px;">
                        <img src="./list.png" style="height: 15px;">
                        <p class="ml-3">For security reasons, you will be able to view this token until the time out. You can always generate the new token If you need one.</p>
                    </div>
                    <div style="display: flex; align-items: center; margin-left: 20px;">
                        <img src="./list.png" style="height: 15px; margin-top: 5px;">
                        <p class="ml-3">To get the hands on this new token use the <b>"copy to clipboard"</b> shown on the input field.</p>
                    </div>
                </div>
                <div style="position: relative; margin-top: 25px;">
                    <input class="input-field" type="text" :value="generated_token" placeholder="Token will be displayed here.." readonly>
                    <img src="./copy.png" style="position: absolute; right: 20px; top: 50%; transform: translateY(-50%); height: 17px; width: 15px; cursor: pointer;" @click="copyToken()">
                </div>
                <div style="text-align: right; margin-bottom: 25px; margin-top: 25px; margin-right: -7px;">
                    <va-button type="submit" class="my-0" @click.prevent="createToken()">Generate Now</va-button>
                </div>
            </div>
        </va-card>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)

export default {
    created() {
          this.$http.get(config.menu.host + '/provider').then(resp => {
          resp.body = resp.body.filter(function(prop,i){
            prop['id']=i+1
            return prop.iptv
          });
          this.providerArr = resp.body        
        this.getAllToken()
    });
},
    data() {
        return {
            totalPages: 0,
            token_name: '',
            isshowgrid: true,
            isshowForm: false,
            platform_name: 'SMS',
            platform_radio_options: ["SMS", "DRM", "EDGE"],
            time_type: 'Days',
            time_radio_options: ["Days", "Hours", "Minutes"],
            token_time: '',
            change_msg: "Enter Days",
            hide_time: "20:00",
            generated_token: "",
            tokenData: [],
            tokenNameError: [],
            providerNameError: [],
            selectTimeErorr: [],
            providerArr: [],
            term: null,
            perPage: '10',
            perPageOptions: ['4', '6', '10', '20'],
            providers: ''
        };
    },
    watch: {
        token_name: function (newVal, oldVal) {
            if (newVal) {
                this.tokenNameError = []
            }
        },
        providers: function (newVal, oldVal) {
            if (newVal) {
                this.providerNameError = []
            }
        },        
        token_time: function (newVal, oldVal) {
            if (newVal) {
                this.selectTimeErorr = []
            }
        }
    },
    computed: {
        fields() {
            return [{
                    name: 'sno',
                    title: 'S.NO',
                    width: '10%',
                },
                {
                    name: 'token_name',
                    title: 'Token Name',
                },
                {
                    name: 'platform_name',
                    title: 'Platform Name',
                },
                {
                    name: 'expiry_time',
                    title: 'Expiry Time',
                },

                {
                    name: '__slot:action',
                    dataClass: 'text-right',
                }
            ]
        },
        filteredData() {
            return search(this.term, this.tokenData)
        },
    },
    methods: {
        getAllToken(page = 0) {
            if (this.term) {
                return this.search(this.term, page)
            }
            var vm = this
            const loader = Vue.$loading.show({ width: 40, height: 40 })
            this.$http.get(config.menu.host + '/token/' + this.perPage + '/' + page).then(response => {
                loader.hide()
                let index = 0
                this.tokenData = []
                this.totalPages = response.body.count
                var start = (page - 1).toString()
                let num = (page == 0) ? 1 : Number(start + 1) // Calculate the total number of pages
                response.body.rows.map(function (data) {
                    data.id = index++
                    data.sno = num++
                    vm.tokenData.push(data)
                })
                this.tokenData = response.body.rows
                this.isshowForm = false
                this.isshowgrid = true
            }, function (error) {
                if (error && error.body) 
                  Vue.notify({ text: error.body,type: 'error'})
              })
        },
        add() {
            this.platform_name = 'SMS';
            this.time_type = 'Days';
            this.getTimeType('Days')
            this.isshowForm = true
            this.isshowgrid = false
        },
        getTimeType(type) {
            this.token_time = ''
            if (type == 'Hours') {
                this.change_msg = "Enter Hours"
            } else if (type == 'Minutes') {
                this.change_msg = "Enter Minutes"
            } else if (type == 'Days') {
                this.change_msg = "Enter Days"
            }
        },
        copyToken() {
            if (!this.generated_token) {
                console.error('Cannot copy empty token.')
                return
            }
            navigator.clipboard.writeText(this.generated_token).then(() => {
                Vue.notify({ text: 'Token copied to clipboard!', type: 'success'})
            }).catch(err => {
                console.error('Failed to copy token:', err)
                Vue.notify({ text: 'Failed to copy token!', type: 'error'})
            })
        },
        createToken() {
            if (!this.token_name || !this.token_time) {
                this.tokenNameError = this.token_name ? [] : ['Token Name is required']
                this.providerNameError = this.providers ? [] : ['Provider is required']
                this.selectTimeErorr = this.token_time ? [] : ['Token Time is required']
            } else if (this.generated_token) {
                Vue.notify({ text: 'Token is already generated for given duration. Try after sometime for new token!', type: 'error'})
            } 
                 else {
                var tokentime = ''
                if (this.time_type == "Days") {
                    tokentime = this.token_time + 'D';
                } else if (this.time_type == 'Hours') {
                    tokentime = this.token_time + 'H';
                } else {
                    tokentime = this.token_time + 'M';
                }
                var payload = {
                    token_name: this.token_name,
                    expiry_time: tokentime,
                    platform_name: this.platform_name,
                    provider_id: this.providers.provider_id
                }
                const loader = Vue.$loading.show({ width: 40, height: 40 })
                this.$http.post(config.menu.host + '/token', payload).then(responseData => {
                    loader.hide()
                    if (responseData && responseData.body) {
                        Vue.notify({ text: 'Token generated successfully!', type: 'success'})
                    }
                    this.generated_token = responseData.body
                    let timeLeft = 20
                    this.hide_time = `${timeLeft}:00`
                    const intervalId = setInterval(() => {
                        timeLeft -= 1
                        if (timeLeft <= 0) {
                            clearInterval(intervalId)
                            this.list()
                        } else {
                            this.hide_time = `${timeLeft}:00`
                        }
                    }, 1000);
                }, errResponse => {
                    loader.hide()
                    if (errResponse && errResponse.body) {
                        Vue.notify({ text: errResponse.body, type: 'error'})
                    }
                })
            }
        },
        list() {
            this.isshowgrid = true
            this.isshowForm = false
            this.generated_token = ''
            this.hide_time = "20:00"
            this.token_name = ''
            this.token_time = ''
            this.getAllToken()
            this.platform_name = 'SMS';
            this.time_type = 'Days';
            this.getTimeType('Days')
            this.providers = ''
        },
        search: debounce(function (term, page = 0) {
            this.term = term
            if (term) {
                var payload = {
                    term: term,
                    limit: this.perPage,
                    offset: page
                }
                const loader = Vue.$loading.show({width: 40, height: 40})
                this.$http.post(config.menu.host + '/token/search', payload).then(response => {
                    loader.hide()
                    let index = 0
                    var vm = this
                    var start = (page - 1).toString()
                    let num = (page == 0) ? 1 : Number(start + 1)
                    this.totalPages = response.body.count
                    this.tokenData = response.body.rows.map(function (data) {
                        data.id = index++
                        data.sno = num++
                        return data
                    })
                    this.isshowForm = false
                    this.isshowgrid = true
                }, function (error) {
                    loader.hide()
                    if (error && error.body) Vue.notify({
                        text: error.body,
                        type: 'error'
                    })
                })
            } else {
                this.getAllToken()
            }
        }, 400),
    },
}
</script>

<style>
  .input-field {
    width: 100%;
    margin: 0 !important;
    padding: 1em;
    padding-right: 35px !important;
    background-color: #eaeaea;
    border-width: 0;
    border-radius: 10px;
    color: gray;
    height: 40px;
  }

  .container {
    background: linear-gradient(to bottom, white 0%, white 50%, #f1f1f1 50%, #f4f3f3 100%);
  }
</style>
